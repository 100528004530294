<script>
import "./plugins/ion.rangeslider.css"
import "./plugins/rangeslider.min.js"

export default {
	name: "IonRange",
	props: {value: Number,attrs: Object,config: {}},
	mounted(){
		$(this.$el).ionRangeSlider(Object.assign({
			skin: "round", // flat, round, big, modern, sharp
			grid: false,
			min: 0,
			max: 100,
			from: this.value,
			postfix: "",
			onStart:(data)=>{
				this.plugin = data.slider;
			},
			onFinish: (data) => {
				this.$emit("input", data.from)
			}
		},this.config));
		setTimeout(()=>{
			if(this.config.sliderOnly){
				$(this.$el).prev().find(".irs-min,.irs-max,.irs-single").remove();
			}
		}, 100)
		this.plugin = $(this.$el).data("ionRangeSlider")
	},
	watch:{
		value(v){ this.plugin.update({from:v}) },
		config(v){ this.plugin.update(v) },
	}
}
</script>

<template>
  <input />
</template>

<style lang="scss">
.irs--round {
	.irs-line, .irs-bar{
		height: 6px;
		top: 32px;
	}
	.irs-handle{
		width: 20px;
		height: 20px;
		top: 25px;
	}
}
</style>